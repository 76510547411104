import * as React from "react"
import { Override, addPropertyControls, ControlType } from "framer"

export function NoZoomSearchOverride(): Override {
    return (props) => {
        // Create a ref to access the input element
        const inputRef = React.useRef(null)

        React.useEffect(() => {
            // This function runs after the component mounts
            const handleInput = () => {
                // Get the input element
                const input = inputRef.current
                if (!input) return

                // Add the necessary attributes to prevent zoom
                input.setAttribute("autocomplete", "off")
                input.setAttribute("autocorrect", "off")
                input.setAttribute("autocapitalize", "off")
                input.setAttribute("spellcheck", "false")

                // This is the key attribute that prevents zooming
                input.setAttribute("inputmode", "search")

                // Set font-size to at least 16px to prevent zoom on iOS
                input.style.fontSize = "16px"
            }

            // Apply once on mount
            handleInput()

            // Target the actual input element within complex components if needed
            const actualInput =
                inputRef.current?.querySelector("input") || inputRef.current
            if (actualInput) {
                actualInput.setAttribute("inputmode", "search")
                actualInput.style.fontSize = "16px"
            }
        }, [])

        // Return the modified props with the ref
        return {
            ...props,
            ref: inputRef,
        }
    }
}

export default NoZoomSearchOverride
